<template lang="pug">
.main-page.row
  el-form.demo-form-inline(:inline="true" :model="searchForm")
    el-form-item(label="日志类型")
      BaseSelect(v-model="searchForm.platform" :list="logTypes" clearable)
    //- el-form-item(label="账户名称")
    //-   el-input(v-model="searchForm.name")
    el-form-item(label="操作时间")
      el-date-picker(
        v-model="searchForm.date"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format='yyyy-MM-dd HH:mm:ss'
      )
    el-form-item
      el-button(type="primary" @click="refresh" v-loading="loading") 查询
      el-button(
        type="success"
        @click="handleExport"
        v-if="pemission['3.1.1']"
        :disabled="!multipleSelection.length"
      ) 导出

  el-table(
    :data="tableData"
    stripe
    fit
    width="800"
    v-loading="loading"
    @selection-change="handleSelectionChange"
  )
    el-table-column(
      type="selection"
      width="55"
    )
    el-table-column(prop="platformText" label="日志类型")
    el-table-column(prop="username" label="账户名称")
    el-table-column(prop="ctime" label="操作时间")
    el-table-column(prop="content" label="具体操作")

  el-pagination(
    background
    layout="prev, pager, next, total"
    :page-size="pageSize"
    :total="total"
    @current-change="pageChange"
  )

</template>

<script>
import BaseSelect from '@/components/BaseSelect.vue';
import { logTypes, LOG_TYPE_TEXT, getPemission } from '@/utils/const';
import request from '@/utils/request';
import { jsonToExcel } from '@/utils/xlsx';

export default {
  components: {
    BaseSelect,
  },
  data() {
    return {
      pemission: getPemission(),
      logTypes,
      searchForm: {
        platform: '',
        date: [],
      },
      loading: false,
      tableData: [],
      total: 0,
      pageSize: 20,
      pageIndex: 1,
      multipleSelection: [],
    };
  },
  methods: {
    refresh() {
      this.pageIndex = 1;
      this.search();
    },
    search() {
      const params = {
        ...this.searchForm,
        page: this.pageIndex,
        pagesize: this.pageSize,
      };
      if (this.searchForm.date && this.searchForm.date.length) {
        const [timeFrom, timeTo] = this.searchForm.date;
        params.starttime = timeFrom;
        params.endtime = timeTo;
      }
      delete params.date;
      this.getData(params);
    },
    getData(params) {
      this.loading = true;
      request({
        method: 'get',
        url: '/manager/operator/get',
        params,
      }).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.count;
          this.tableData = res.data.list.map((e) => ({
            ...e,
            platformText: LOG_TYPE_TEXT[e.platform],
          }));
        } else {
          this.$message.error(res.msg);
        }
      })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },
    handleExport() {
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择要导出的数据');
        return;
      }
      const json = this.multipleSelection;
      jsonToExcel({
        json,
        sheetName: 'sheet1',
        fileName: '小程序操作log.xlsx',
        retType: 'file',
      });
    },
    pageChange(e) {
      this.pageIndex = e;
      this.search();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val);
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style>

</style>
